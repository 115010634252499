import React from "react";
import ServicioItem from "./ServicioItem";
import convenciones from "../assets/icons/convenciones.svg";
import formaciones from "../assets/icons/formaciones.svg";
import reunionesTrabajo from "../assets/icons/reunionesTrabajo.svg";
import eventosEmpresas from "../assets/icons/eventosEmpresas.svg";
import presentaciones from "../assets/icons/presentaciones.svg";
import catering from "../assets/icons/catering.svg";

const ServicioList = () => (
  <div className="grid grid-cols-1 gap-4 font-barlow text-center sm:grid-cols-2 md:m- xl:grid-cols-3">
    <ServicioItem icon={convenciones} title="CONVENCIONES" />
    <ServicioItem icon={formaciones} title="FORMACIONES" />
    <ServicioItem icon={reunionesTrabajo} title="REUNIONES DE TRABAJO" />
    <ServicioItem icon={eventosEmpresas} title="EVENTOS DE EMPRESA" />
    <ServicioItem icon={presentaciones} title="PRESENTACIONES" />
    <ServicioItem icon={catering} title="SERVICIO DE CÁTERING" />
  </div>
);

export default ServicioList;
