import Header from "../components/Header";

import Hotel from "../assets/img/hotel.webp";
import Eventos from "../assets/img/eventos.avif";

const CateringsPage = () => {
  return (
    <div className="font-barlow bg-gray-100 text-oxfordBlue">
      <Header />
      <section className="bg-ziggurat">
        <div className="flex flex-col md:flex-row">
          <img
            src={Hotel}
            alt="Fachada Hotelet elRetiro"
            className="w-full h-48 md:w-1/2 md:h-80 object-cover object-bottom 2xl:h-96"
          />
          <div className="w-11/12 h-auto m-auto md:w-1/2">
            <div className="pt-4 pb-14 m-auto sm:pb-20 md:w-10/12 md:pb-4 lg:py-4">
              <h2 className="text-xl font-extrabold md:mb-4 md:text-2xl lg:text-3xl">
                ¡Haz que cada momento sea único!
              </h2>
              <p className="font-semibold md:mb-2 md:text-lg lg:text-xl">
                Con los mejores profesionales que harán que tu evento sea un
                éxito
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-11/12 lg:w-9/12 mx-auto flex">
        <div className="mx-auto flex flex-col mb-16 xl:flex-row">
          <img
            src={Eventos}
            alt="Logo eventos"
            className="w-48 h-48 mt-16 mx-auto xl:mr-8 xl:w-80 xl:h-80 xl:-mt-24"
          />
          <div className="mt-auto">
            <h4 className="mt-4 text-center text-xl font-extrabold italic md:mb-4 md:text-2xl lg:text-3xl">
              Hacemos realidad tus sueños
            </h4>
            <p className="max-w-[650px] py-3 px-6 bg-ziggurat rounded-3xl text-lg font-medium text-center xl:py-6 xl:px-16">
              Un pequeño hotel y restaurante con un gran equipo que con cariño
              esperamos organizar este dia tan especial
            </p>
          </div>
        </div>
      </section>

      <section className="w-11/12 mx-auto max-w-[1400px] xl:flex">
        <div className="mx-auto flex flex-wrap justify-center mb-16 gap-8 xl:flex-nowrap">
          <aside className="w-11/12 mx-auto max-w-[440px] flex flex-col gap-4 md:w-[47%] lg:max-w-[500px] xl:w-1/3 xl:max-w-[450px]">
            <p className="bg-oxfordBlue rounded-2xl py-5 px-4 text-center text-white text-7xl font-gloriant">
              Espacios
            </p>
            <p className="px-6 py-4 bg-ziggurat rounded-2xl lg:text-lg">
              Nuestro restaurante ofrece una variedad de espacios versátiles y
              elegantes, diseñados para satisfacer todas tus necesidades. Desde
              reuniones íntimas hasta grandes celebraciones, contamos con el
              ambiente perfecto para cada ocasión. <br />
              Nuestros salones se caracterizan por ser espacios amplios y
              diáfanos, adaptables a eventos de diversa índole. Ideales para
              bodas, banquetes y grandes celebraciones. Su diseño flexible
              permite configurar el espacio según las necesidades específicas
              del evento. <br />
              También contamos con una terraza exterior, perfecta para bodas al
              aire libre, recepciones y eventos de verano. Cuenta con una
              piscina donde poder relajarse después de un gran banquete.
            </p>
          </aside>
          <aside className="w-11/12 mx-auto max-w-[440px] flex flex-col gap-4 md:w-[47%] lg:max-w-[500px] xl:w-1/3 xl:max-w-[450px]">
            <p className="bg-oxfordBlue rounded-2xl py-5 px-4 text-center text-white text-7xl font-gloriant">
              Eventos
            </p>
            <p className="px-6 py-4 bg-ziggurat rounded-2xl xl:text-lg">
              En elRetiro llevamos más de 25 años creando recuerdos inolvidables
              para todos nuestros clientes. Nos especializamos en una amplia
              variedad de celebraciones, incluyendo bautizos, comuniones, bodas,
              cumpleaños y todo tipo de eventos sociales y corporativos. <br />
              Nuestro compromiso es ofrecerte un servicio personalizado,
              adaptándonos a tus necesidades y deseos para hacer de tu evento
              una experiencia única y memorable. Contamos con un equipo de
              profesionales dedicados que se encargarán de cada detalle, desde
              la planificación inicial hasta la ejecución final, asegurando que
              todo salga a la perfección.
            </p>
          </aside>
          <aside className="w-11/12 mx-auto max-w-[440px] flex flex-col gap-4 md:w-[47%] lg:max-w-[500px] xl:w-1/3 xl:max-w-[450px]">
            <p className="bg-oxfordBlue rounded-2xl py-5 px-4 text-center text-white text-7xl font-gloriant">
              Gastronomia
            </p>
            <p className="px-6 py-4 bg-ziggurat rounded-2xl text-lg">
              Ofrecemos una variedad de menús diseñados para satisfacer todos
              los gustos. Ya sea para una cena íntima, una celebración especial
              o un evento corporativo, contamos con opciones que se adaptan
              perfectamente a cada ocasión. <br />
              Nos enorgullece nuestra capacidad para adaptarnos a las
              preferencias y requisitos de nuestros clientes. Si tienes alguna
              solicitud especial o necesidad dietética, nuestro equipo estará
              encantado de personalizar el menú para garantizar que todos
              disfruten de una experiencia culinaria excepcional. <br />
              Utilizamos siempre ingredientes frescos y de temporada. Cada plato
              es preparado con esmero y atención al detalle, asegurando que cada
              bocado sea una verdadera delicia.
            </p>
          </aside>
        </div>
      </section>
    </div>
  );
};

export default CateringsPage;
