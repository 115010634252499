import React from "react";
import { Carousel } from "@material-tailwind/react";

import Header from "../components/Header";
import ServicioList from "../components/ServicioList";
import Footer from "../components/Footer";
import FooterBar from "../components/FooterBar";

import Hotel from "../assets/img/hotel.webp";
import Vilafortuny from "../assets/img/salon-vilafortuny.jpg";
import Toni from "../assets/img/salon-toni.jpg";
import Oasis from "../assets/img/oasis.jpg";
import Habitacion from "../assets/img/habitacion.webp";

import "../css/ForBusiness.css";

const ForBusinessPage = () => {
  return (
    <div className="bg-gray-100 font-barlow text-oxfordBlue">
      <Header />

      <section className="bg-ballBlue/60">
        <div className="flex flex-col md:flex-row">
          <img
            src={Hotel}
            alt="Fachada Hotelet elRetiro"
            className="w-full h-48 md:w-1/2 md:h-80 object-cover object-bottom 2xl:h-96"
          />
          <div className="w-11/12 h-auto m-auto text-white md:w-1/2">
            <div className="pt-4 pb-10 m-auto md:w-10/12 md:pb-4 lg:py-4">
              <h2 className="text-2xl font-extrabold md:mb-4 lg:text-3xl">
                ¡Descubre todo lo que podemos ofrecerte!
              </h2>
              <p className="font-semibold text-lg md:mb-2 lg:text-xl">
                Con los mejores profesionales que harán que tu evento sea un
                éxito.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-10/12 max-w-[1400px] m-auto mt-5">
        <h3 className="mb-8 text-center text-3xl font-extrabold">
          ¡Más de 30 años ayudándote a crecer!
        </h3>
        <ServicioList />

        <Carousel
          className="mt-8 h-72 overflow-hidden mx-auto sm:h-56 lg:h-60 xl:h-52 2xl:h-44"
          id="carrousel"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="mt-8 p-6 bg-ballBlue/[.20] rounded-3xl shadow-md">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i
                      ? "w-8 bg-ballBlue/[.20]"
                      : "w-4 bg-white/60"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          <div className="mx-auto pt-8 pb-14 object-cover bg-ballBlue/[.20] rounded-2xl text-center lg:w-full">
            <p className="text-xl font-extrabold">PORT DE TARRAGONA</p>
            <p className="px-10 mt-4 text-lg font-semibold">
              Todo fantástico. La comida muy buena y el trato del personal muy
              correcto. En general para repetir.
            </p>
          </div>
          <div className="mx-auto pt-8 pb-14 object-cover bg-ballBlue/[.20] rounded-2xl text-center lg:w-full">
            <p className="text-xl font-extrabold">PARC SAMA</p>
            <p className="px-10 mt-4 text-lg font-semibold">
              Enviamos nuestros clientes a comer al Restaurante elRetiro.
              Sabemos que van a quedar encantados.
            </p>
          </div>
          <div className="mx-auto pt-8 pb-14 object-cover bg-ballBlue/[.20] rounded-2xl text-center lg:w-full">
            <p className="text-xl font-extrabold">GAM</p>
            <p className="px-10 mt-4 text-lg font-semibold">
              El alojamiento ha sido impecable, muy cómodo. Venimos a menudo a
              comer y no conocemos una mejor relación calidad y precio.
            </p>
          </div>
          <div className="mx-auto pt-8 pb-14 object-cover bg-ballBlue/[.20] rounded-2xl text-center lg:w-full">
            <p className="text-xl font-extrabold">SCHUTZ</p>
            <p className="px-10 mt-4 text-lg font-semibold">
              Gracias por hacernos una tarifa especial y por el evento de
              empresa en vuestras instalaciones.
            </p>
          </div>
        </Carousel>
      </section>

      <section className="w-10/12 max-w-[1400px] m-auto mt-5">
        <h3 className="mt-10 text-center text-3xl font-extrabold">
          DESCUBRE NUESTRAS SALAS
        </h3>

        <section className="max-w-[1400px] m-auto mt-6">
          {/* <div className="grid grid-cols-2 gap-4">
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              SALÓN VILAFORTUNY
            </p>
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              SALÓN TONI
            </p>
          </div> */}
          <aside className="salas-izquierda">
            <div>
              <h5>Salón Vilafortuny</h5>
              <ul>
                <li>
                  Amplia sala con vistas a Vilafortuny situada en la primera
                  planta
                </li>
                <li>Disponible acceso en ascensor</li>
                <li>Salida directa a la terraza de 25m² desde la sala</li>
                <li>Capacidad para hasta 100 personas</li>
                <li>Pantalla gigante y equipo de audio</li>
              </ul>
            </div>
            <img src={Vilafortuny} alt="Piscina" />
          </aside>
          <aside className="salas-derecha">
            <div>
              <h5>Salón Toni</h5>
              <ul>
                <li>
                  Gran espacio diáfano perfecto para formaciones o conferencias
                </li>
                <li>Acceso directo a la zona de piscina</li>
                <li>Capacidad para hasta 500 personas</li>
                <li>Pantalla gigante, equipo de audio y equipo de luces</li>
                <li>Posibilidad de montar un escenario</li>
              </ul>
            </div>
            <img src={Toni} alt="" className="w-96" />
          </aside>
          {/* <div className="grid grid-cols-2 gap-4 mt-4">
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              HOTELET
            </p>
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              OASIS
            </p>
          </div> */}
          <aside className="salas-izquierda">
            <div>
              <h5>Hotelet</h5>
              <ul>
                <li>
                  Disponemos de 54 habitaciones para que disfrutes de una noche
                  de descanso después de tus eventos
                </li>
              </ul>
            </div>
            <img src={Habitacion} alt="" />
          </aside>
          <aside className="salas-derecha">
            <div>
              <h5>Oasis</h5>
              <ul>
                <li>Gran espacio de terraza con piscina</li>
                <li>Escenario, pantalla gigante, equipo de audio y de luces</li>
                <li>Zona VIP con sofás disponible</li>
                <li>Múltiples barras</li>
                <li>Entrada privada</li>
              </ul>
            </div>
            <img src={Oasis} alt="" />
          </aside>
        </section>
      </section>
      <Footer></Footer>
      <FooterBar></FooterBar>
    </div>
  );
};

export default ForBusinessPage;
