import React from "react";

const ServicioItem = ({ icon, title }) => (
  <div className="flex items-center py-2 px-4 bg-ballBlue/[.20] text-center rounded-3xl shadow-md font-barlow">
    <div className="w-32 flex justify-center text-center">
      <img src={icon} alt={title} className="h-16 my-auto mr-4 md:h-20" />
    </div>
    <p className="w-full text-xl text-center font-extrabold md:text-2xl">
      {title}
    </p>
  </div>
);

export default ServicioItem;
