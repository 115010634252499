import React from "react";
import { useEffect } from "react";
import Header from "../components/Header";

import "../css/Bedrooms.css";

const HGContainer = () => {
  useEffect(() => {
    // Configuración de las opciones
    window.optionsHG = {
      pcode: "421",
      typo: "apartments",
      lang: "es",
      index: 1234,
      min_occupancy: 1,
      max_occupancy: 4,
      num_room_col: 4,
    };

    // Inicializar iniHG después de establecer optionsHG
    if (typeof iniHG === "function") {
      window.iniHG();
    } else {
      console.error("iniHG function is not available");
    }
  }, []);

  return (
    <div className="font-barlow">
      <Header className="mb-14" />
      <div
        id="hgContainer_1234"
        style={{ width: "100%", margin: "0 auto" }}
        className="container-fluid hgContainer"
      ></div>
    </div>
  );
};

export default HGContainer;
