import React from "react";

import Phone from "../assets/icons/phone2.svg";
import Calendar from "../assets/icons/calendar.svg";
import Pin from "../assets/icons/pin.svg";
import Whatsapp from "../assets/icons/whatsapp.svg";

const BottomNavigation = () => {
  return (
    <div className="w-full fixed bottom-0 bg-ballBlue shadow-[0_0_20px_0_rgba(0,0,0,0.4)] font-barlow lg:hidden">
      <div className="flex justify-evenly py-2">
        <a href="tel:977364400">
          <img src={Phone} alt="977364400" className="w-10 text-white" />
        </a>
        <a
          href="https://booking.hotelgest.com/v4/?pcode=421"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Calendar} alt="" className="w-10 text-white" />
        </a>
        <a
          href="https://www.google.es/maps/place/Hotelet+elRetiro/@41.0888083,1.0920404,17z/data=!4m9!3m8!1s0x12a14531d1c3b235:0x4b60cc820e96b8cc!5m2!4m1!1i2!8m2!3d41.0902333!4d1.0935388!16s%2Fg%2F1vttzs0s?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Pin} alt="" className="w-10 text-white" />
        </a>
        <a
          href="https://wa.me/34977364400"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Whatsapp} alt="" className="w-10 text-white" />
        </a>
      </div>
    </div>
  );
};

export default BottomNavigation;
