import React, { useState } from "react";
import { Link } from "react-router-dom";
import elRetiro from "../assets/img/logo-hotel.png";
import menuIcon from "../assets/icons/menuIcon.svg";
import xIcon from "../assets/icons/xIcon.svg";

import "../css/Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="max-w-[1800px] mx-auto text-black py-2 px-4 flex justify-between items-center font-barlow">
      <Link to="/">
        <img
          src={elRetiro}
          alt="Logo elRetiro"
          className="w-28 md:w-32 lg:w-40"
        />
      </Link>
      <nav>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`text-xl md:hidden ${isOpen ? "rotate" : ""}`}
        >
          {isOpen ? (
            <img src={xIcon} alt="Cerrar menú" />
          ) : (
            <img src={menuIcon} alt="Abrir menú" />
          )}
        </button>

        <ul
          className={`absolute shadow-[0_30px_40px_-15px_rgba(0,0,0,0.3)] md:shadow-none md:static bg-gray-100 md:bg-transparent w-full left-0 md:flex flex-col md:flex-row transition-transform transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } md:translate-x-0`}
        >
          <li className="border-b md:border-none">
            <Link
              to="/"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
            >
              Inicio
            </Link>
          </li>
          <li className="border-b md:border-none">
            {/* <Link
              to="/habitaciones"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
            >
              Habitaciones
            </Link> */}
            <a
              href="https://booking.hotelgest.com/v4/?pcode=421"
              target="_blank"
              rel="noopener noreferrer"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
            >
              Habitaciones
            </a>
          </li>
          <li className="border-b md:border-none">
            <a
              href="https://restaurantelretiro.com/"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
              target="_blank"
              rel="noreferrer"
            >
              Restaurante
            </a>
          </li>
          <li className="border-b md:border-none">
            <Link
              to="/empresas"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
            >
              Para Empresas
            </Link>
          </li>
          <li className="border-b md:border-none">
            <Link
              to="/caterings"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
            >
              Banquetes
            </Link>
          </li>
          <li className="border-b md:border-none">
            <a
              href="#contacto"
              className="block py-2 px-4 rounded-xl hover:bg-gray-200"
            >
              Contacto
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
